import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';


const Skills = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const skillsRef = useNav('Skills');

	return (
		<section ref={skillsRef} id='skillsContainer'>
			<h1 id="skillsTitle">Skills</h1>
			<div id="skills1">
				<p>I've had a ton of fun learning how to code. I have taken full advantage of Codecademy's Web Development program.</p>
				<p>I'm very skilled at HTML, CSS, and JavaScript.</p>
				<p>I'm getting better every day at React.js, Node.js, and Express.js</p>
				<p>I'm also super into learning about responsive design with a focus on making the web accessible to those with disabilities.</p>
			</div>
			<div id="skills2">
				<p>I'm also great at my current job. I'm the head bartender, mixologist, <a href="https://www.mastersommeliers.org/">Certified Sommelier</a>, at <a href="https://www.thearthotel.com"> The Art, a Hotel</a> in Denver Colorado.</p>
				<p>Check out my media mentions below.</p>
			</div>
		</section>
	);
};

export default Skills;