import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import denverLife from '../photos/denverlife.png';
import washingtonian from '../photos/washingtonian.png';
import liquor from '../photos/liquor.png';

const Media = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const mediaRef = useNav('Media');

	return (
		<section ref={mediaRef} id='mediaContainer'>
			<div id='mediaDiv'>
                <h1 id="mediaTitle">Media Mentions</h1>
                <div id="photoDiv">
                    <a className="photo" href="https://www.denverlifemagazine.com/bartender-e-scott-moser/" target="_blank"><img src={denverLife} /> </a> 
                    <a className="photo" href="https://www.washingtonian.com/2018/02/08/how-a-food-editor-hacks-valentines-day-dining-around-dc/" target="_blank"><img src={washingtonian} /></a> 
                    <a className="photo" href='https://www.liquor.com/best-rums-for-daiquiris-5115288' target="_blank"><img src={liquor} /></a> 
               </div>
            </div>
		</section>
	);
};

export default Media;