import React from 'react';
import { Home, About, Contact, Skills, Media } from './';

const Main = () => (
	<main>
		<Home />
		<About />
		<Skills />
		<Media />
		<Contact />
		
	</main>
);

export default Main;
