import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';

const About = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const aboutRef = useNav('About');

	return (
		<section ref={aboutRef} id='aboutContainer'>
				<img id="aboutImg" src={require("../photos/meandpups.jpg")} alt="Me and my dog knuckles" />
				<div id="aboutText">
					<h3 id='aboutTitle'>ABOUT</h3>
					<div id="aboutP">
						<p>Thanks for stopping by!</p>
						<p>I'm a hospitality professional looking to break into the world of Tech.</p>
						<p>I've been learning Web Development through <a href="www.codecademy.com">Codecademy</a> since March 2020.</p>
						<p>Living through Covid has awakened a passion for technology and the limitless world it represents.</p>
						<p>I love coding and solving problems, and my decade of experience in hospitality has proven that I'm great with people, too.</p>
					</div>
				</div>	
		</section>
	);
};

export default About;