import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import facebook from "../photos/facebook.png";
import linkedin from '../photos/linkedin.png';
import instagram from '../photos/instagram.png';
import gmail from '../photos/gmail.png';
import resume from '../photos/resume.png';
import resumeDoc from '../photos/esmresume.pdf'

const Contact = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const contactRef = useNav('Contact');

	return (
		<section ref={contactRef} id='contactContainer'>
			
				<h1 id="contactTitle">Contact Me!</h1>
				<p id="contactText">Feel free to reach out to me on any of the links below</p>
				<div id='socials'>
					<a href="https://www.facebook.com/scottiemo" target="_blank"><img src={facebook}/></a>
					<a href="https://www.linkedin.com/in/esmoser/" target="_blank"><img src={linkedin}/></a>
					<a href="https://www.instagram.com/e.s.moser/" target="_blank"><img src={instagram}/></a>
					<a href="https://mail.google.com/mail/u/1/?view=cm&fs=1&to=edward.moser@gmail.com&tf=1" target="_blank"><img src={gmail}/></a>
				</div>
				<div id='resume'>
					<a href={resumeDoc} target="_blank"><img src={resume}/></a>
					<p>click here to see my resume</p>
				</div>
				<div id='footer'>
					<p>This page was coded by me, E. Scott Moser</p>
					<p>Copyright 2023</p>
				</div>
		</section>
		
	);
};

export default Contact;