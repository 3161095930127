import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';

const Home = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const homeRef = useNav('Home');

	return (
		<section ref={homeRef} id='homeContainer'>
			<div id='homeDiv'>
				<p id="hi">Hi!</p>
				
				<div id='homeP'>
					<p>I'm Scott</p>
					<p>A Full Stack Web Developer</p>
					<p>Available For Hire</p>
				</div>
			</div>
		</section>
	);
};

export default Home;